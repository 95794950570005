import axios from "axios";

const API_ROOT_SELF = "/api/v1/user";
const API_ROOT = "/api/v1/users";
const ep = {
	user: `${API_ROOT_SELF}`,
	users: `${API_ROOT}`,
};

async function getUser() {
	try {
		const res = await axios.get(ep.user);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function getAllUsers() {
	try {
		const res = await axios.get(ep.users);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function updateUser(data) {
	// role, groupId
	try {
		const res = await axios.patch(ep.users, data);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

export default {
	getUser,
	getAllUsers,
	updateUser,
};
