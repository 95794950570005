// import auth from "./auth";
import user from "./user";
import noty from "./noty";
import group from "./group";

export default {
	// auth,
	user,
	noty,
	group,
};
