<template>
	<div class="user" :class="user ? '_logged-in' : ''">
		<v-menu content-class="user-menu" :left="true" :disabled="!user" offset-y>
			<template v-slot:activator="{ on, attrs }">
				<button
					class="user__button"
					v-bind="attrs"
					v-on="user ? on : { click: toLogin }"
					:title="user ? userText : null"
				>
					<v-icon v-if="!user" class="user__icon">$user</v-icon>
					<v-icon v-if="user" class="user__icon user__icon--auth">$userAuth</v-icon>
					<span v-if="!isMobileView" class="user__text">{{ userText }}</span>
				</button>
			</template>
			<v-list v-if="user">
				<v-list-item>
					<router-link to="/conversions" class="mr-auto user__link"> My conversions </router-link>
				</v-list-item>
				<v-list-item>
					<router-link to="/invoice" class="mr-auto user__link"> Invoice </router-link>
				</v-list-item>
				<v-list-item v-if="user.role === rolesTypes.ADMIN">
					<router-link to="/users" class="mr-auto user__link"> Users </router-link>
				</v-list-item>
				<v-list-item>
					<button @click="logout" class="user__link">Log out</button>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { mapState } from "vuex";
import authService from "@/services/auth.js";
import $router from "@/router";
import { rolesTypes } from "../../shared/configs/roles.js";

export default {
	name: "User",
	data: function () {
		return {
			buttonText: "",
			rolesTypes,
		};
	},
	computed: {
		...mapState({
			user: (state) => state.user.userData,
		}),
		isMobileView() {
			return this.$vuetify.breakpoint.xs;
		},
		userText() {
			if (this.user) {
				return this.user.email;
			} else {
				return "Log in";
			}
		},
	},
	methods: {
		toLogin() {
			$router.push({ name: "login" }).catch(() => {});
		},
		logout() {
			authService.logout();
		},
	},
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.user {
	margin-left: 1.563em; // 25px

	&__icon {
		color: currentColor !important;

		svg,
		circle,
		path {
			stroke: currentColor;
		}

		&--auth {
			transform: scale(1.5);
		}

		.arrow {
			transition: transform 0.3s ease;
			transform-origin: center center;
		}
	}

	&__button {
		color: $color-manatee !important;
		width: 4.375em; // 70px

		&:hover {
			color: $color-wistful !important;
		}

		&:active,
		&[aria-expanded="true"] {
			color: $color-light-slate-blue !important;

			.arrow {
				transform: scaleY(-1);
			}
		}
	}

	&__text {
		display: block;
		white-space: nowrap;
		font-size: 0.813em; // 13px
		transition: color 0.3s ease;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&._logged-in {
		.user__icon {
			color: $color-light-slate-blue !important;
		}
	}

	@media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
		font-size: 14px;

		.v-icon__component {
			width: 20px;
			height: 20px;
		}
	}
}
</style>
