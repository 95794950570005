import axios from "axios";

const API_ROOT = "/api/v1/group";
const ep = {
	group: `${API_ROOT}`,
};

async function getGroups() {
	try {
		const res = await axios.get(ep.group);
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function createGroup(name) {
	try {
		const res = await axios.post(ep.group, { name });
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function updateGroup(id, name) {
	try {
		const res = await axios.patch(ep.group, { id, name });
		return res.data;
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

async function removeGroup(id) {
	try {
		const params = new URLSearchParams();
		params.append("id", id);
		await axios.delete(ep.group, { params });
	} catch (error) {
		const message = error.response.data ? error.response.data.message : error.response.statusText;
		throw new Error(message);
	}
}

export default { getGroups, updateGroup, createGroup, removeGroup };
