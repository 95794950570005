import userService from "@/services/user.js";
import { rolesTypes } from "../../../shared/configs/roles.js";

export default {
	namespaced: true,
	state: {
		userData: null,
		usersList: [],
	},
	getters: {
		hasActiveSub: (state) => {
			return (
				state.userData?.paid ||
				state.userData?.role === rolesTypes.AGENT ||
				state.userData?.role === rolesTypes.ADMIN
			);
		},
	},
	mutations: {
		SET_USER: (state, user) => {
			state.userData = user;
		},
		SET_USERS_LIST: (state, users) => {
			state.usersList = users;
		},
		UPDATE_LIST_USER: (state, user) => {
			const oldUserIdx = state.usersList.findIndex((u) => u.email === user.email);
			if (oldUserIdx >= 0) {
				Object.assign(state.usersList[oldUserIdx], user);
			}
		},
		CLEAN_LIST_USER_GROUP: (state, groupId) => {
			state.usersList.forEach((u) => {
				if (u.groupId === groupId) {
					u.groupId === null;
				}
			});
		},
	},
	actions: {
		async setUser({ commit }) {
			try {
				const { user } = await userService.getUser();

				if (user !== null) commit("SET_USER", user);
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},
		async setUsersList({ commit }) {
			try {
				const users = await userService.getAllUsers();

				commit("SET_USERS_LIST", users);
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},
		async updateListUser({ commit }, data) {
			// email, role, groupId
			try {
				const user = await userService.updateUser(data);

				commit("UPDATE_LIST_USER", user);

				return user;
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},
	},
};
