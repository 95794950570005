import groupService from "@/services/group.js";
import $store from "../";

export default {
	namespaced: true,
	state: {
		groups: [],
	},
	mutations: {
		SET_GROUPS: (state, groups) => {
			state.groups = groups;
		},
		CREATE_GROUP: (state, group) => {
			state.groups.push(group);
		},
		UPDATE_GROUP: (state, group) => {
			const oldGroupIdx = state.groups.findIndex((g) => g._id === group._id);
			if (oldGroupIdx >= 0) state.groups[oldGroupIdx] = group;
		},
		DELETE_GROUP: (state, id) => {
			const oldGroupIdx = state.groups.findIndex((g) => g._id === id);
			if (oldGroupIdx >= 0) state.groups.splice(oldGroupIdx, 1);
		},
	},
	actions: {
		async setGroups({ commit }) {
			try {
				const groups = await groupService.getGroups();
				if (groups?.length) commit("SET_GROUPS", groups);
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},

		async createGroup({ commit }, { name }) {
			try {
				const group = await groupService.createGroup(name);
				commit("CREATE_GROUP", group);
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},

		async updateGroup({ commit }, { id, name }) {
			try {
				const group = await groupService.updateGroup(id, name);
				commit("UPDATE_GROUP", group);
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},

		async removeGroup({ commit }, { id }) {
			try {
				await groupService.removeGroup(id);
				commit("DELETE_GROUP", id);
				$store.commit("user/CLEAN_LIST_USER_GROUP", { groupId: id });
			} catch (error) {
				commit("noty/NEW", { type: "error", message: error.message }, { root: true });
			}
		},
	},
};
