import ErrorNotFound from "../views/ErrorNotFound.vue";
import { successPageAccess, roleAccess } from "./middlewares";
import { rolesTypes } from "../../shared/configs/roles.js";

const routes = [
	{
		path: "/",
		name: "main",
		meta: {
			title: "PDF to XHTML converter",
			up: true,
		},
		component: () => import(/* webpackChunkName: "main" */ "../views/ContentMain.vue"),
	},

	{
		path: "/success",
		name: "success",
		meta: {
			title: "Download XHTML",
			downloadData: null,
		},
		excludeSeo: true,
		props: ({ meta }) => {
			return { email: meta.email, downloadData: meta.downloadData, fromPaid: meta.fromPaid };
		},
		beforeEnter: successPageAccess,
		component: () => import(/* webpackChunkName: "order-success" */ "../views/OrderSuccess.vue"),
	},
	// {
	// 	path: "error",
	// 	meta: {
	// 		title: "Order error",
	// 		showGuide: true,
	// 	},
	// 	component: () => import(/* webpackChunkName: "order-error" */ "../views/OrderError.vue"),
	// },

	// {
	// 	path: "/esef",
	// 	meta: {
	// 		title: "What is ESEF",
	// 	},
	// 	component: () => import(/* webpackChunkName: "esef" */ "../views/StaticEsef.vue"),
	// },
	// {
	// 	path: "/news",
	// 	meta: {
	// 		title: "News",
	// 		up: true,
	// 	},
	// 	component: () => import(/* webpackChunkName: "news" */ "../views/StaticNews.vue"),
	// },
	{
		path: "/faq",
		meta: {
			title: "FAQ & Support",
			up: true,
		},
		component: () => import(/* webpackChunkName: "faq" */ "../views/StaticFaq.vue"),
	},
	{
		path: "/privacy-policy",
		name: "terms-conditions",
		meta: {
			title: "Privacy Policy",
		},
		excludeSeo: true,
		component: () => import(/* webpackChunkName: "terms" */ "../views/StaticTerms.vue"),
	},
	{
		path: "/terms-conditions",
		name: "terms-conditions-of-use",
		meta: {
			title: "Terms & Conditions of Use",
		},
		excludeSeo: true,
		component: () => import(/* webpackChunkName: "terms" */ "../views/StaticTermsOfUse.vue"),
	},
	{
		path: "/cookie-policy",
		name: "cookie-policy",
		meta: {
			title: "Cookie Policy",
		},
		excludeSeo: true,
		component: () => import(/* webpackChunkName: "terms" */ "../views/StaticCookiePolicy.vue"),
	},
	// {
	// 	path: "/technical-requirements",
	// 	meta: {
	// 		title: "Technical requirements",
	// 	},
	// 	component: () =>
	// 		import(/* webpackChunkName: "tech-req" */ "../views/StaticTechAndCond.vue"),
	// },
	{
		path: "/contacts",
		meta: {
			title: "Contacts",
		},
		component: () => import(/* webpackChunkName: "contacts" */ "../views/StaticContacts.vue"),
	},
	{
		path: "/invoice",
		meta: {
			title: "Invoice",
			// TODO Отключена для тестирования
			// requireAuth: true,
		},
		component: () => import(/* webpackChunkName: "Invoice" */ "../views/OrderInvoice.vue"),
	},
	{
		path: "/convert-report",
		name: "convert-report",
		meta: {
			title: "Convert report",
		},
		component: () => import(/* webpackChunkName: "convert-report" */ "../views/ConvertReport.vue"),
	},
	{
		path: "/convert-demo",
		name: "convert-demo",
		meta: {
			title: "Convert free demo",
			demo: true,
		},
		component: () => import(/* webpackChunkName: "convert-demo" */ "../views/ConvertReport.vue"),
	},
	{
		path: "/conversions",
		name: "conversions",
		meta: {
			title: "My conversions",
			requireAuth: true,
		},
		component: () => import(/* webpackChunkName: "conversions" */ "../views/Conversions.vue"),
	},
	{
		path: "/users",
		name: "users",
		meta: {
			title: "Users",
			requireAuth: true,
			requiredRole: rolesTypes.ADMIN,
		},
		beforeEnter: roleAccess,
		component: () => import(/* webpackChunkName: "users" */ "../views/Users.vue"),
	},
	{
		path: "/login",
		name: "login",
		excludeSeo: true,
		meta: {
			title: "Login",
			redirAuth: true,
		},
		component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
	},
	// {
	// 	path: "/ui-kit",
	// 	dev: true,
	// 	meta: {
	// 		title: "UiKit",
	// 	},
	// 	component: () => import(/* webpackChunkName: "ui-kit" */ "../views/UiKit.vue"),
	// },
	{
		path: "*",
		props: true,
		meta: {
			title: "404 Page Not Found",
		},
		excludeSeo: true,
		component: ErrorNotFound,
	},
];

export default routes;
